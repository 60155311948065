import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";

//CSS
import "../css/main.css";

export default function ListPage() {
  return (
    <StaticQuery
      query={graphql`
        {
          posts: allContentfulBlogPostPage(
            sort: { order: DESC, fields: date }
          ) {
            nodes {
              slug
              author
              date
              header
              image {
                fluid {
                  src
                }
              }
              mainText {
                json
              }
            }
          }
        }
      `}
      render={(data) => (
        <Layout className="bg-light">
          {/* <SEO title={data.list.nodes[0].seo} /> */}

          <Container className="blog-list">
            <h1>Blogposts</h1>
            {data.posts.nodes.map((post) => (
              <Col xs={12} key={post.id} className="list-item">
                <Link to={post.slug}>
                  <h4>{post.header}</h4>
                  <Row className="mx-0">
                    <p className="author">{post.author}</p>
                    <p className="date">{new Date(post.date).toDateString()}</p>
                  </Row>
                  <div className="image-wrapper">
                    <img src={post.image.fluid.src} alt={post.image.title} />
                  </div>
                  <div className="rich-text">
                    {documentToReactComponents(post.mainText.json)}
                  </div>
                </Link>
              </Col>
            ))}
          </Container>
        </Layout>
      )}
    />
  );
}
